
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Year from '@/modules/common/types/year.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type Day from '@/modules/common/types/day.type';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import FleetDayScanBtn from '@/modules/cars/modules/fleet/components/fleet-day-scan-btn.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import moment from 'moment';
import DayChanger from '../../../../common/components/day-changer.vue';
import LoaderWrapper from '../../../../common/components/loader-wrapper.vue';

interface ITableData {
    brokerName: string,
    total: number,
    myBroker: boolean,
}

@Component({
    components: {
        ModalWrapper,
        DayChanger,
        PopupEventsContainer,
        LoaderWrapper,
        CompsetScale,
        FleetDayScanBtn,
    },
    filters: {
        PriceFilter: (value: any) => value || 0,
    },
})
export default class FleetPopup extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: String,
    })
    private source?: string;

    private brokerCount: number = 0;
    private position: number = 0;

    get compsetFraction() {
        if (this.isSoldOut || this.isNA) {
            return '';
        }
        return `#${this.position}/${this.brokerCount}`;
    }

    get compsetText() {
        if (this.isNA) {
            return 'N/A';
        }
        if (this.isSoldOut) {
            return 'Sold out';
        }
        return '';
    }

    get carClasses() {
        return this.fleetService.carCategoriesByDay(this.day, this.source);
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get tableData(): ITableData[] | [] {
        let tableData: ITableData[] = [];
        const { currentCompany } = this.userService;

        const data = this.fleetService.brokersDataByDay(this.day, this.source);

        if (!data || !currentCompany) {
            return tableData;
        }

        tableData = data;

        if (tableData && tableData.findIndex(item => item.brokerName === currentCompany) === -1) {
            tableData.push({
                brokerName: currentCompany,
                myBroker: true,
                total: 0,
            });
        }

        tableData.sort((a, b) => b.total - a.total);

        this.brokerCount = tableData.length;
        this.position = tableData.findIndex(item => item.brokerName === this.userService.currentCompany) + 1;

        return tableData;
    }

    get cardColorClass() {
        if (this.isSoldOut || this.isNA) {
            return {
                grey: true,
            };
        }
        if (!this.position || !this.brokerCount) {
            return {};
        }
        const percentPosition: number = (this.position / this.brokerCount) * 100;

        return {
            green: this.position === 1 || percentPosition <= 50,
            yellow: percentPosition > 50 && this.position !== this.brokerCount,
            red: this.position === this.brokerCount,
        };
    }

    get outOfRange() {
        return this.fleetService.isOutOfRange(this.day, this.source);
    }

    get isSoldOut() {
        return this.fleetService.isSoldOut(this.day, this.source);
    }

    get isNA() {
        return this.fleetService.isNa(this.day, this.source) === 0;
    }

    get currentPath() {
        return this.$route.path;
    }

    get formatScanDate() {
        if (this.fleetService === null || !this.fleetService.finishScanDate) {
            return '--/--/--';
        }

        const currentDate = moment();
        const scanDate = this.fleetService.finishScanDate;

        const today = currentDate.clone().startOf('day');
        const yesterday = currentDate.clone().subtract(1, 'days').startOf('day');

        let todayOrYesterday = '';
        if (moment(scanDate).isSame(yesterday, 'd')) {
            todayOrYesterday = 'yesterday';
        } else if (moment(scanDate).isSame(today, 'd')) {
            todayOrYesterday = 'today';
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday}, ${moment(scanDate).format('LT')}`;
        }

        return moment(scanDate).format('DD/M/YYYY');
    }
}
